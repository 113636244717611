//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const ProductInfo: IWidget = {
  id: uuid(),
  name: 'ProductInfo',
  order: 0,
  drop: 0,
  category: 'Required',
  open: false,
  label: {
    id: 'productinfo',
    name: 'Product Info',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_ProductInformation',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_ProductInformation'),
          value: getValue('WidgetHeadline_ProductInformation'),
        },
        '2': {
          feId: 'ShowWidgetHeadline',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowWidgetHeadline'),
          value: getValue('ShowWidgetHeadline'),
        },
        '3': {
          feId: 'ProductInfoDescription',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('ProductInfoDescription'),
          value: getValue('ProductInfoDescription'),
        },
        '4': {
          feId: 'ProductInfoBasicInformation',
          change: true,
          translate: true,
          data: true,
          valueType: 'keyValue',
          name: getDesc('ProductInfoBasicInformation'),
          value: getValue('ProductInfoBasicInformation'),
        },
      },
    },
    {
      id: 'Nutrients',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Nutrients',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Nutrients'),
          value: getValue('WidgetHeadline_Nutrients'),
        },
        '2': {
          feId: 'ProductInfoNutrients',
          change: true,
          translate: false,
          data: true,
          valueType: 'keyValue',
          name: getDesc('ProductInfoNutrients'),
          value: getValue('ProductInfoNutrients'),
        },
        '3': {
          feId: 'ProductInfoCustomFields',
          change: true,
          translate: true,
          data: true,
          valueType: 'keyValue',
          name: getDesc('ProductInfoCustomFields'),
          value: getValue('ProductInfoCustomFields'),
        },
        '4': {
          feId: 'ServingUnit',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('ServingUnit'),
          value: getValue('ServingUnit'),
        },
        '5': {
          feId: 'ReferenceQuantity',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('ReferenceQuantity'),
          value: getValue('ReferenceQuantity'),
        },
        '6': {
          feId: 'ServingSize',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('ServingSize'),
          value: getValue('ServingSize'),
        },
        '7': {
          feId: 'EnableServingSize',
          change: true,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('EnableServingSize'),
          value: getValue('EnableServingSize'),
        },
      },
    },
    {
      id: 'Ingredients',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Ingredients',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Ingredients'),
          value: getValue('WidgetHeadline_Ingredients'),
        },
        '2': {
          feId: 'ProductInfoIngredients',
          change: true,
          translate: true,
          data: true,
          valueType: 'list',
          name: getDesc('ProductInfoIngredients'),
          value: getValue('ProductInfoIngredients'),
        },
        '3': {
          feId: 'ProductInfoAllergens',
          change: true,
          translate: true,
          data: true,
          valueType: 'list',
          name: getDesc('ProductInfoAllergens'),
          value: getValue('ProductInfoAllergens'),
        },
        '4': {
          feId: 'ProductInfoNotesAndWarnings',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('ProductInfoNotesAndWarnings'),
          value: getValue('ProductInfoNotesAndWarnings'),
        },
      },
    },
    {
      id: 'Distributor',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Distributor',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Distributor'),
          value: getValue('WidgetHeadline_Distributor'),
        },
        '2': {
          feId: 'ProductInfoResponsibleDistributor',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('ProductInfoResponsibleDistributor'),
          value: getValue('ProductInfoResponsibleDistributor'),
        },
        '3': {
          feId: 'PostalCode',
          change: false,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('PostalCode'),
          value: getValue('PostalCode'),
        },
        '4': {
          feId: 'City',
          change: true,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('City'),
          value: getValue('City'),
        },
        '5': {
          feId: 'Street',
          change: true,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Street'),
          value: getValue('Street'),
        },
        '6': {
          feId: 'Link',
          change: true,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Link'),
          value: getValue('Link'),
        },
        '7': {
          feId: 'Email',
          change: true,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('Email'),
          value: getValue('Email'),
        },
        '8': {
          feId: 'ProducedIn',
          change: true,
          translate: false,
          data: true,
          valueType: 'value',
          name: getDesc('ProducedIn'),
          value: getValue('ProducedIn'),
        },
      },
    },
    {
      id: 'Storage',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Storage',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Storage'),
          value: getValue('WidgetHeadline_Storage'),
        },
        '2': {
          feId: 'ProductInfoStorageFields',
          change: true,
          translate: true,
          data: true,
          valueType: 'keyValue',
          name: getDesc('ProductInfoStorageFields'),
          value: getValue('ProductInfoStorageFields'),
        },
      },
    },
  ],
  style: {
    objects: {
    },
  },
};
