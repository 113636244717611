import { useCallback } from 'react';
import { m } from 'framer-motion';
// @mui

import MenuItem from '@mui/material/MenuItem';

// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';

import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { IconButton } from '@mui/material';

// ----------------------------------------------------------------------

export default function LanguageSelect() {
  const locales = useLocales();

  const popover = usePopover();

  const handleChangeLang = useCallback(
    (newLang: string) => {
      locales.onChangeLang(newLang);
      popover.onClose();
    },
    [locales, popover]
  );

  return (
    <>
      {/* @ts-ignore */}
      <IconButton
        component={m.button}
        whileTap="tap"
        onClick={popover.onOpen}
        sx={{
          width: 36,
          height: 36,
          padding: 1,
        }}
      >
        <Iconify icon={locales.currentLang.icon} sx={{ width: 'auto' }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {locales.allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
