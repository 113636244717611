import { ILangWidget } from 'src/types/product';

export const LangCertificates: ILangWidget = [
  {
    feId: 'Certificate_BioBayern',
    desc: [
      { lang: 'en', val: 'Bio Bayern' },
      { lang: 'de', val: 'Bio Bayern' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Certificate_Demeter',
    desc: [
      { lang: 'en', val: 'Demeter' },
      { lang: 'de', val: 'Demeter' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Certificate_Naturland',
    desc: [
      { lang: 'en', val: 'Naturland' },
      { lang: 'de', val: 'Naturland' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
    ],
  },
  {
    feId: 'Certificate_Bioland',
    desc: [
      { lang: 'en', val: 'Bioland' },
      { lang: 'de', val: 'Bioland' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Certificate_Bml',
    desc: [
      { lang: 'en', val: 'EU-Bio' },
      { lang: 'de', val: 'EU-Bio' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Certificate_VLabel',
    desc: [
      { lang: 'en', val: 'Vegan' },
      { lang: 'de', val: 'Vegan' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];