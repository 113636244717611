import { ILangWidget } from 'src/types/product';

export const LangSustainability: ILangWidget = [
  {
    feId: 'WidgetHeadline_Sustainability',
    desc: [
      { lang: 'en', val: 'Sustainability' },
      { lang: 'de', val: 'Nachhaltigkeit' },
    ],
    value: [
      { lang: 'en', val: ['Sustainability'] },
      { lang: 'de', val: ['Nachhaltigkeit'] },
    ],
  },
  {
    feId: 'SustainabilityDescription',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Beschreibung' },
    ],
    value: [
      { lang: 'en', val: ['Description'] },
      { lang: 'de', val: ['Beschreibung'] },
    ],
  },
  {
    feId: 'WidgetHeadline_Initiatives',
    desc: [
      { lang: 'en', val: 'Sustainability Initiatives' },
      { lang: 'de', val: 'Nachhaltigkeitsinitiativen' },
    ],
    value: [
      { lang: 'en', val: ['Sustainability Initiatives'] },
      { lang: 'de', val: ['Nachhaltigkeitsinitiativen'] },
    ],
  },
  {
    feId: 'SubheadlineInitiatives',
    desc: [
      { lang: 'en', val: 'Subheadline Initiatives' },
      { lang: 'de', val: 'Subheadline Initiative' },
    ],
    value: [
      { lang: 'en', val: ['Fair prices for our farmers'] },
      { lang: 'de', val: ['Faire Preise für unsere Landwirte'] },
    ],
  },
  {
    feId: 'InitiativePicture',
    desc: [
      { lang: 'en', val: 'Initiative Picture' },
      { lang: 'de', val: 'Initiative Bild' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
    ],
  },
  {
    feId: 'InitiativeFilename',
    desc: [
      { lang: 'en', val: 'Initiative Filename' },
      { lang: 'de', val: 'Initiative Datei' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
    ],
  },
  {
    feId: 'InitiativeShowPicture',
    desc: [
      { lang: 'en', val: 'InitiativeShowPicture' },
      { lang: 'de', val: 'InitiativeShowPicture' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
    ],
  },
  {
    feId: 'InitiativeDesc',
    desc: [
      { lang: 'en', val: 'Initiative Description' },
      { lang: 'de', val: 'Initiative Beschreibung' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
    ],
  },
  {
    feId: 'WidgetHeadline_Truecost',
    desc: [
      { lang: 'en', val: 'True Cost' },
      { lang: 'de', val: 'Wahre Kosten' },
    ],
    value: [
      { lang: 'en', val: ['True Cost'] },
      { lang: 'de', val: ['Wahre Folgekosten'] },
    ],
  },
  {
    feId: 'TrueCostProductDesc',
    desc: [
      { lang: 'en', val: 'Product Description' },
      { lang: 'de', val: 'Produkt Beschreibung' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostProductUVP',
    desc: [
      { lang: 'en', val: 'Product UVP' },
      { lang: 'de', val: 'Produkt UVP' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostProduct',
    desc: [
      { lang: 'en', val: 'Product True Cost' },
      { lang: 'de', val: 'Produkt Wahre Kosten' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostCompareDesc',
    desc: [
      { lang: 'en', val: 'Compare Description' },
      { lang: 'de', val: 'Vergleich Beschreibung' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostCompareUVP',
    desc: [
      { lang: 'en', val: 'Compare UVP' },
      { lang: 'de', val: 'Vergleich UVP' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostCompare',
    desc: [
      { lang: 'en', val: 'Compare True Cost' },
      { lang: 'de', val: 'Vergleich Wahre Kosten' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostCauses',
    desc: [
      { lang: 'en', val: 'True Cost Causes' },
      { lang: 'de', val: 'Wahre Kosten Ursachen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostStudy',
    desc: [
      { lang: 'en', val: 'True Cost Study' },
      { lang: 'de', val: 'Wahre Kosten Studie' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'TrueCostStudyName',
    desc: [
      { lang: 'en', val: 'True Cost Study' },
      { lang: 'de', val: 'Wahre Kosten Studie' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Co2',
    desc: [
      { lang: 'en', val: 'CO₂e-Footprint' },
      { lang: 'de', val: 'CO₂e-Fußabdruck' },
    ],
    value: [
      { lang: 'en', val: ['CO₂e-Footprint'] },
      { lang: 'de', val: ['CO₂e-Fußabdruck'] },
    ],
  },
  {
    feId: 'Co2Values',
    desc: [
      { lang: 'en', val: 'CO₂e Values' },
      { lang: 'de', val: 'CO₂e Werte' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Water',
    desc: [
      { lang: 'en', val: 'Water-Footprint' },
      { lang: 'de', val: 'Wasser-Fußabdruck' },
    ],
    value: [
      { lang: 'en', val: ['Water-Footprint'] },
      { lang: 'de', val: ['Wasser-Fußabdruck'] },
    ],
  },
  {
    feId: 'WaterValues',
    desc: [
      { lang: 'en', val: 'Water Values' },
      { lang: 'de', val: 'Wasser Werte' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];