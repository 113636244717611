import { ILangWidget } from 'src/types/product';

export const LangCustomWidget: ILangWidget = [
  {
    feId: 'WidgetHeadline_CustomWidget',
    desc: [
      { lang: 'en', val: 'Custom Headline' },
      { lang: 'de', val: 'Custom Headline' },
    ],
    value: [
      { lang: 'en', val: ['Custom Headline'] },
      { lang: 'de', val: ['Custom Headline'] },
    ],
  },
  {
    feId: 'WidgetIcon_CustomWidget',
    desc: [
      { lang: 'en', val: 'Custom Icon' },
      { lang: 'de', val: 'Custom Icon' },
    ],
    value: [
      { lang: 'en', val: [''] },
      { lang: 'de', val: [''] },
    ],
  },
];