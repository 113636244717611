import { ILangWidget } from 'src/types/product';

export const LangFooter: ILangWidget = [
  {
    feId: 'WebsiteURL',
    desc: [
      { lang: 'en', val: 'Website URL' },
      { lang: 'de', val: 'Webseite URL' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowWebsiteURL',
    desc: [
      { lang: 'en', val: 'Show Website URL' },
      { lang: 'de', val: 'Webseite URL anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'XURL',
    desc: [
      { lang: 'en', val: 'X URL' },
      { lang: 'de', val: 'X URL' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowXURL',
    desc: [
      { lang: 'en', val: 'Show X URL' },
      { lang: 'de', val: 'X URL anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'InstagramURL',
    desc: [
      { lang: 'en', val: 'Instagram URL' },
      { lang: 'de', val: 'Instagram URL' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowInstagramURL',
    desc: [
      { lang: 'en', val: 'Show Instagram URL' },
      { lang: 'de', val: 'Instagram URL anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'FacebookURL',
    desc: [
      { lang: 'en', val: 'Facebook URL' },
      { lang: 'de', val: 'Facebook URL' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowFacebookURL',
    desc: [
      { lang: 'en', val: 'Show Facebook URL' },
      { lang: 'de', val: 'Facebook URL anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'LinkedinURL',
    desc: [
      { lang: 'en', val: 'Linkedin URL' },
      { lang: 'de', val: 'Linkedin URL' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowLinkedinURL',
    desc: [
      { lang: 'en', val: 'Show Linkedin URL' },
      { lang: 'de', val: 'Linkedin URL anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WebsiteName',
    desc: [
      { lang: 'en', val: 'Website Name' },
      { lang: 'de', val: 'Webseitenname' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Imprint',
    desc: [
      { lang: 'en', val: 'Imprint' },
      { lang: 'de', val: 'Impressum' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];