//
import { ITemplate } from 'src/types/templates';
import { _widgets } from './_widgets';
// ----------------------------------------------------------------------

const EmptyAttribute = {
  feId: '',
  change: false,
  translate: false,
  data: false,
  valueType: 'value',
  name: [
    { lang: 'en', val: '' },
    { lang: 'de', val: '' },
  ],
  value: [
    { lang: 'en', val: [''] },
    { lang: 'de', val: [''] },
  ],
};

export const _emptyAttribute = EmptyAttribute;
